import React from "react"
import { md } from "mill/utils/breakpoints"
import styled from "styled-components"

export const Frame = styled.div`
  margin: 0 auto;
  overflow: hidden;
  background: white;
  margin-top: 1rem;
  margin-bottom: 2rem;

  ul {
    margin: 0;
    padding: 0;
    li:last-of-type {
      border-bottom: none;
    }
  }
  @media ${md} {
    display: flex;
  }
`

export const Question = styled.h2`
  padding: 1.5rem;
  margin: 0;
  font-size: 1.6rem;
  font-weight: 600;
  border-bottom: 1px solid #f1f1f1;
  white-space: pre-line;
  @media ${md} {
    font-size: 2.4rem;
    border-bottom: none;
    padding: 3rem 4rem;
  }
`

export const Option = styled.div`
  font-size: 1.3rem;
  padding: 2rem 2.5rem;
  font-weight: normal;
  list-style: none;
  line-height: 1.9rem;
  border-bottom: 1px solid #f1f1f1;
  margin: 0;
  display: flex;

  > div {
    min-width: 3rem;
    max-width: 3rem;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      margin: 1px 0 0 1px;
    }
  }

  span {
    display: flex;
    align-items: center;
    flex: 1;
  }

  ${props =>
    props.selected &&
    `
  cursor: pointer;
  background-color: #f1f1f1;
  transition: all 0.s ease-in;
  `};

  @media ${md} {
    &:hover {
      cursor: pointer;
      background-color: #efefef;
      transition: all 0.1s ease-in;
    }
  }
`

export const QuestionContainer = styled.div`
  @media ${md} {
    flex: 1;
    border: 2px solid #f1f1f1;
    border-right-width: 1px;
  }
`

export const AnswerContainer = styled.div`
  @media ${md} {
    flex: 1;
    border: 2px solid #f1f1f1;
    border-left-width: 1px;
  }
`
